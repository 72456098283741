import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=4cb3dc68&"
import script from "./SiteFooter.vue?vue&type=script&lang=ts&"
export * from "./SiteFooter.vue?vue&type=script&lang=ts&"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/button/Button.vue').default,ScenkonstSite: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/navigation/ScenkonstSite.vue').default,SiteFooterContactList: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/navigation/SiteFooter/ContactList.vue').default,SocialMediaLinks: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/navigation/SocialMediaLinks.vue').default,SiteFooterSecondaryNav: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/navigation/SiteFooter/SecondaryNav.vue').default,SiteFooterPrimaryNav: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/navigation/SiteFooter/PrimaryNav.vue').default})
